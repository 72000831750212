import React from "react";

export default function WelcomeShow() {
  return (
    <div>
      <h1>My First Heading</h1>

      <a href="/login">Login App.</a>
    </div>
  );
}
