import React from "react";
import TableUser from "../../components/tableUser";

export default function HomePage() {
  return (
    <div>
      <TableUser />
    </div>
  );
}
